import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import Thumb from '../../assets/svgs/drawkitFullStackManColour.svg';
import Jumbotron from '../../components/Jumbotron';
import { SECONDARY } from '../../utils/colors';

const PrivacyPage = ({ location }) => (
  <Layout theme="blue" location={location}>
    <SEO
      title="Poliítica de Privacidade da Central do Frete"
      description="Política de Privacidade da Central do Frete"
    />
    <Jumbotron
      background={SECONDARY}
      title="Política de Privacidade"
      subtitle="A Central do Frete leva sua privacidade à sério."
      Image={Thumb}
    />
    <Container className="pt-5 pb-5">
      <p>
        Ao utilizar o site da Central do Frete você está dando-nos permissão
        para contatá-lo sobre os nossos serviços de frete.
      </p>

      <p>
        Qualquer informação fornecida no site somente será utilizada para
        conclusão dos pedidos de cotação e coleta ou para sua informação sobre
        serviços e ofertas que possam ser do seu interesse.
      </p>

      <p>
        A Central do Frete não divulga qualquer informação pessoal a nenhum
        terceiro.
      </p>

      <p>
        {`Se por algum motivo você deseja modificar a sua assinatura, remover seu
        nome de nossa lista de contatos, ou tiver alguma dúvida, entre em
        contato conosco através do e-mail `}
        <a href="mailto:falecom@centraldofrete.com">
          falecom@centraldofrete.com
        </a>
      </p>
    </Container>
  </Layout>
);

PrivacyPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default PrivacyPage;
